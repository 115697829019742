import axios from 'axios';

const getSubscribeApiCall = (code, success, error) => {
  axios.get('https://api.strangerchat.io/api/v1/ranchatusers/code/' + code + '/subscribe')
  // axios.get('http://localhost:8090/api/v1/ranchatusers/code/' + code + '/subscribe')
    .then(success)
    .catch(error)
}

const unsubscribeApiCall = (code, success, error) => {
  axios.post('https://api.strangerchat.io/api/v1/ranchatusers/code/' + code + '/unsubscribe')
  // axios.post('http://localhost:8090/api/v1/ranchatusers/code/' + code + '/unsubscribe')
    .then(success)
    .catch(error)
}

export const applist = [
  {
    "code": "randomchat",
    "icon": "https://play-lh.googleusercontent.com/r971y7GVE1GXAfrNzkAAIMuZJ_mcapRMDNmiDzqi4-FqzZy90HLgCZc0zGOFGzQLO64=w240-h480-rw",
    "name": "Random Chat (Stranger Chat)",
    "promotion": "https://lh3.googleusercontent.com/IdjLnWeSWdIbW14VvAEuBuM0Pwg17ZA4p_1IvhVBpg5aHWxTafjenokd667SJ9F2Fg",
    "package": "kr.jungrammer.randomchat",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "secretchat",
    "icon": "https://play-lh.googleusercontent.com/pHGKbFWbXPUIcupnRLqR8oWCs5MLJ8WM5xvwaHtKGQmOPyz7h9qtsL8mO5xVVdgprh0=w240-h480-rw",
    "name": "Secret Chat (Random Chat)",
    "promotion": "https://lh3.googleusercontent.com/Wp2wB5RIhgyO_HU4t19z--pygyp9zBDs-hZJNBcxzmpsITKooaJ7I5dW8jKaOxZr6Q",
    "package": "kr.jungrammer.secretchat",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "ranchat",
    "icon": "https://play-lh.googleusercontent.com/Tz5S2NEkOW1qpsqXvafZ2PNorhERSDu9FFEV5fcMnZt08ut59fiSFZLC2a4gvtxi2EI=w240-h480-rw",
    "name": "Chat with Stranger - Ranchat",
    "promotion": "https://lh3.googleusercontent.com/ru9mQWV4kxIxEp-LS-UDvkb4rLHRY5WQqa6advXafgBx-_3BSzK0siTRt9tBZTQUW55L",
    "package": "kr.jungrammer.ranchat",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "aiassistant",
    "icon": "https://play-lh.googleusercontent.com/XOrTQNOixsPy397Kx3HCY4DoOazbI_vJLKJITjWxJG7tSvjSJ7d-_GGiobvi7mL4-w=s256-rw",
    "name": "AI Chat Assistant (By GPT)",
    "package": "io.forestsoft.secretarychat",
    "promotion": "https://lh3.googleusercontent.com/MoQ_Qnb7k6ndaCJLWFng2qFfmE5ettdUkQEpmwAK8GpDxDB8_Ds4GRiEv03abX_vPps",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "aipainter",
    "icon": "https://lh3.googleusercontent.com/7aeLBgts4v-1QxtAxFZqen54cyBhq6vFh6PL3C7plFPEt1k1FFJl6APbMKDiRqGSoN1E",
    "name": "AI Art Painter - Forest Studio",
    "package": "io.forestsoft.aipainter",
    "promotion": "https://lh3.googleusercontent.com/wDjLFD4jSvQ3OlsF4VqE5_gPxAy6sGNAKPsoKkp8O89uE0e1xWRoBeYr25q_bDQ9OA",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "strangerchat",
    "name": "Stranger with Chat (Random)",
    "icon": "https://play-lh.googleusercontent.com/-YH-wdFbBjahkV0kSEMeux_xtWNgpLAN0chxcsnC69WfT8EJdnQ3O3-L5gYiZoNLDrXa=w240-h480-rw",
    "package": "kr.jungrammer.strangerchat3",
    "promotion": "https://lh3.googleusercontent.com/Agsr7RRXxo225K-L-vmITFEcqx8vaPxa6vAdSjui4W0b4ZNApfCEPnjv9Rl2kph4F6I",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "drugchat",
    "name": "Drug Chatting - Random Chat",
    "icon": "https://play-lh.googleusercontent.com/ouDHjcJmIh5KlfdW3KjOcY1klgH_J6ZYq3BG8aOtQdczXxqo0I9Zp5J-SWrnIlBieMtU=w240-h480-rw",
    "package": "kr.jungrammer.drugchat2",
    "promotion": "https://lh3.googleusercontent.com/WMcgutQqJtmsBNui1tFt_MzCcsMYgtUCGYQddnut24SwhhxxVewIk9NZhb296G1FRA",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "superranchat",
    "icon": "https://play-lh.googleusercontent.com/3eutUxkJdFHX5gnu1oZUnO3Ul2B53dSgV1B9MbQP8uEunLzUGCf1nndp3NCUHau4rRo=w240-h480-rw",
    "name": "Random Chat (with Stranger)",
    "package": "kr.jungrammer.superranchat",
    "promotion": "https://lh3.googleusercontent.com/qzI9Sv_f-qvcABPBowbCvT7lXaDfBbUz6YHfaQigveHFx2C-knrrtnAHLWnatf4KHQ",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "rantalk2",
    "icon": "https://play-lh.googleusercontent.com/cd7HgS7Wvujo0jZLOgSjttaBl-_DsbnPLUVgZvmAj4ErzdotGfsDuLojW_5gLfAWiNU=w240-h480-rw",
    "name": "RanTalk - Random Chat",
    "package": "kr.jungrammer.rantalk2",
    "promotion": "https://lh3.googleusercontent.com/ivJR1mxO9pr6VIvTbo_yZAtm6Q2jvFRdYyP_TI-ElZa68r2bAFHLiON7wxo6y5fVAmo",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "randomtalk",
    "icon": "https://play-lh.googleusercontent.com/G2-XpliNuTDa9RVII8JReSV-NMKu4z24KmZXtY-8m3JsyTQccUGt-Qonn4cSOKxZ4Q=w240-h480-rw",
    "name": "Random Talk, Stranger Chat",
    "package": "kr.jungrammer.randomtalk",
    "promotion": "https://lh3.googleusercontent.com/i1S1Qb1NiMzpdh_z0NhAmTsXp6AWPjzd_lHdjpdIE3emnnGSpFvjkGRH6R5rP8lyVXg",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "chattalk",
    "icon": "https://play-lh.googleusercontent.com/e9dH05QDBrPttwsqii9tune4d_v79F5AZYK57Y6wYcucQ0tydUyeJ2KmByFtOTw7fg=w240-h480-rw",
    "name": "Chat Talk (Random Chat)",
    "package": "kr.jungrammer.chattalk",
    "promotion": "https://lh3.googleusercontent.com/q5CxuRu6mJxFRYoZSSZzIUigs9_K6baAazlIZEZEXef1j4lFgIjQsfGY5ZwTUAYuzOVz",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "orangetalk",
    "icon": "https://play-lh.googleusercontent.com/1QX-aZ9jw_uwNFgC4sPIEwItkb1wCxbnLdfj9q3CrIerKOnClXrP7oW171BnOR139Dqb=w240-h480-rw",
    "name": "Yellow Talk",
    "package": "kr.jungrammer.orangetalk",
    "promotion": "https://lh3.googleusercontent.com/TVfxIxiqOZ69xXJ08yAvfA2nAC7M1w5rQbznvtFEW5U56ssKUNdMxEBUACzuKy8t9ps",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "talkchat",
    "icon": "https://play-lh.googleusercontent.com/m4Bp5m7NxteQ5VljB5BJoXEhd2jDS0eXCXlZEOogEzNNH1CNfdHdoxFA9RSwMiDYeg=w240-h480-rw",
    "name": "Talk Chat - Random Chat",
    "package": "kr.jungrammer.talkchat",
    "promotion": "https://lh3.googleusercontent.com/I_KQMZbaYMT5XVKH71GEVDLfjlTVmpQ4mSfUNhCZ0KlFkMpBYPcKVNruMogqaGR24Q",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "bluetalk",
    "icon": "https://play-lh.googleusercontent.com/jn3rPM5SJjpFIrrb2sDad_3pmWlEeG040xuKrSS7nrk4uwsvX2IpM4l5Mlsd1ZfstnB3=w240-h480-rw",
    "name": "Blue Talk (Random Chat)",
    "package": "kr.jungrammer.bluetalk",
    "promotion": "https://lh3.googleusercontent.com/rI5JzC484x8cAlUuGqaHsZiNU3WzB2KJHIX_mx8teoorQJPLXMr7vBe9BaEfVFacpA",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "candytalk",
    "icon": "https://play-lh.googleusercontent.com/ozD0Nb-6L2I8RfYiBo5e5E4xV83iMn0SGTGn_c4WTiAP382slo-ggIaMwObaClg6Gvil=w240-h480-rw",
    "name": "Candy Talk - Random Chat",
    "package": "kr.jungrammer.candytalk",
    "promotion": "https://lh3.googleusercontent.com/tPmKlquWOnpEi4QcNYIp_PyXI0SOCW18Y1nqOGdivQVMsQVc74Q7WfibJGg31fpF4MeJ",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "honeytalk",
    "icon": "https://play-lh.googleusercontent.com/uUgFF8CXbIBDnEmjyxS3eHzYD5k7WZUSpXFVoasxtauym8xVLi6jeLjIm6yWEF-2rSQ=w240-h480-rw",
    "name": "Honey Talk - Random Chat",
    "package": "kr.jungrammer.honeytalk",
    "promotion": "https://lh3.googleusercontent.com/yNWROfnbBm0RIOSYYFw2bCuQPoXhecs3wSr_VuxFM_lD5aLWx_hmSk-qhD4GGzitqZ8",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "snowtalk",
    "icon": "https://play-lh.googleusercontent.com/6ZBN64cW926jqL1bB46TBODBLi0O_h1mZd7zQQpTPM7WnEDQ43l4GjLyo6J3P4uuMNE=w240-h480-rw",
    "name": "Snow Talk",
    "package": "kr.jungrammer.rantalk",
    "promotion": "https://lh3.googleusercontent.com/LnHRyXl4DVhdIcs6jX7iOue1ktsYCNFAGXAXVUWY5xM8J_jCcdBp8kOmxkRm4QRVnA",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "moatalk",
    "icon": "https://play-lh.googleusercontent.com/sj9rJUf6wntCJRTH81P3j6bdjiYdeZPRXvrPpkTkfNgfWBzjaQaM7gArUNNcpyPl1Bs=w240-h480-rw",
    "name": "Moa Talk, Random Chatting",
    "package": "kr.jungrammer.moatalk",
    "promotion": "https://lh3.googleusercontent.com/4iSy3tRyW8pzHu7IHREsCTKuzLBIi88XVC3vgrcvF9HVo2iWoVlCd1LbYV72lR4C5_E",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "duettalk",
    "icon": "https://play-lh.googleusercontent.com/9XwRcemMMmVL9qdMQdaUx17J1xNIalR5DmB0p3SvNwqNDgrDnzchph1HuxAVFaZmpQ=w240-h480-rw",
    "name": "Duet Talk, Random Chatting",
    "package": "kr.jungrammer.duettalk",
    "promotion": "https://lh3.googleusercontent.com/U2sQeLbKki-oATGV7xwLM1MoI5QDEY7fi9mDKqdeYge4czONw57F_lzJiP6podqUgvBt",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "ssomtalk",
    "icon": "https://play-lh.googleusercontent.com/FkzotvNB0IW5r5pi3y_-8yVDcPHoQag0krdPdk7FzO4WmI06Xt5_vBZR-hCLtaeTobE=w240-h480-rw",
    "name": "SSOM Talk - Random Chatting",
    "package": "kr.jungrammer.ssomtalk",
    "promotion": "https://lh3.googleusercontent.com/Q_pSNMc5mnxAOD-YOYgr0C_SXq6C-gyMkltMFf0zw5hDv_Kt9MKo02DF_acDjSsDjak",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "anonymous",
    "icon": "https://play-lh.googleusercontent.com/fVq0U4fn3tD32vo2aq45ly6llAVfc3gjPZn81b17sWDBig4Ias3nujKomXXVTwTIz94=w240-h480-rw",
    "name": "Anonymous Chat - (Random Chat)",
    "package": "kr.jungrammer.anonymous",
    "promotion": "https://lh3.googleusercontent.com/TAT6K2lfPimmnLXr3ly-tUliL3u2iIC5n-ZIf9U6MUmMjGSPLUUoXDtxdNbtd69cavY",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "talktalk",
    "icon": "https://play-lh.googleusercontent.com/8fa07zZ8fPkWsRjZdUbtvrzxqQF8oo9-ZDxtDJ9P0iMskS0xKTtJqJ5Cx-qvObzd6zk=w240-h480-rw",
    "name": "Talk Talk - Random Chatting",
    "package": "kr.jungrammer.talktalk",
    "promotion": "https://lh3.googleusercontent.com/LG4xxePRssJxKWNG5XzxkDzmfE76g3EtXzLTHI3X2Mmy4eQgkUf7L9tm2ISoVXipP_J7",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "strangertalk",
    "icon": "https://play-lh.googleusercontent.com/vIN7OOE-lqoqG5fuz27sjPX8zBoePzqpbaL3eQ_vulRkPzbeQgCD4b8VBTjnO20tdfA=w240-h480-rw",
    "name": "Stranger Talk (Random Chat)",
    "package": "kr.jungrammer.strangertalk",
    "promotion": "https://lh3.googleusercontent.com/xVxd1nAuGDTPvza06bd00R2DIxnXcMIgfdfh2KiakGp0XnQwECyyu0_eoM7-a8uEfg",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "withstranger",
    "icon": "https://play-lh.googleusercontent.com/VFwgN_d7SkUc9LDdeGRutRsDwFxE_Hg3M9AMZUdpFMb3vQTGuL9GiaqT1_oj-oCOyw=w240-h480-rw",
    "name": "With Stranger - Chatting",
    "package": "kr.jungrammer.withstranger",
    "promotion": "https://lh3.googleusercontent.com/_0reEJLnQGrBeLRhIsL3Ll6P8mu6JEMSheg0aSyjraZLeHywC60IoBKSKf2uLotXbmQ",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "ranchatting",
    "icon": "https://play-lh.googleusercontent.com/ViTMg5agHMB6gzw7M-2DEhURAnNxcVcUDGq9H0bHdXanPWTxpmgXv0VqLmkMpH968YI=w240-h480-rw",
    "name": "Ranchat - Random Chatting",
    "package": "kr.jungrammer.ranchatting",
    "promotion": "https://lh3.googleusercontent.com/T8aK9oEd3Jjly1wcFMH-gnMXkc-jzMZG43efbjHyLiH0UhdLVBApR34fQwP8omv_imM",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "smiletalk",
    "icon": "https://play-lh.googleusercontent.com/lOWaCChiEKU7T5hC9k_62RjgWaIdwuLHfqV61v16PSA8FO_i90rhOufigAdi_Y1zH6wR=w240-h480-rw",
    "name": "Smile Talk - Random Chat",
    "package": "kr.jungrammer.smiletalk",
    "promotion": "https://lh3.googleusercontent.com/9o3NQkCD5z2Raz4cxCJmCw7Y7bxjk-7dcO-67Kzq6ixNA5PbSiEQxF51Ol8dJzqeiQ",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "pinkranchat",
    "icon": "https://play-lh.googleusercontent.com/fje9sMdPprTqBlK8Ij4u7TD49X9aExjHM9_uevw4YO48Kjry6l64tiGNNyIbD5HHdA4=w240-h480-rw",
    "name": "Random Chat - Chatting",
    "package": "kr.jungrammer.pinkranchat",
    "promotion": "https://lh3.googleusercontent.com/ufQcO1ekRAbzGAE0D9sfV-qzbRewcVdnY1LP4iIWeI2fjAS4b0zRbgEUi2eSjhYV-Ikc",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "chathub",
    "icon": "https://play-lh.googleusercontent.com/qMQiy8ipjFXs7P5RWuGeNkAoqFbr1R-hZS7cx_kRoJQgwadB7KQkPPs9DzxFgj5Z=w240-h480-rw",
    "name": "Chatting Hub (Random Chat)",
    "package": "kr.jungrammer.chathub",
    "promotion": "https://lh3.googleusercontent.com/3RLph1jrB9xSGzyBHNMKw-0Nh0R8mwI5ltZKz23NiMuHO87LJV1agB7-Y1tfGDbNKg",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "ranchat_onestore",
    "icon": "https://lh3.googleusercontent.com/-wiqicpgpoeCOm-q56SIPcHfKw2UcdAY6oe_8yOHwCkWIgL8Rg2wB1FonYSJC439s40J",
    "name": "가가채팅 (원스토어)",
    "package": "kr.jungrammer.ranchat.onestore",
    "promotion": "https://lh3.googleusercontent.com/ru9mQWV4kxIxEp-LS-UDvkb4rLHRY5WQqa6advXafgBx-_3BSzK0siTRt9tBZTQUW55L",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "randomchat_onestore",
    "icon": "https://play-lh.googleusercontent.com/r971y7GVE1GXAfrNzkAAIMuZJ_mcapRMDNmiDzqi4-FqzZy90HLgCZc0zGOFGzQLO64=w240-h480-rw",
    "name": "랜덤챗 (원스토어)",
    "package": "kr.jungrammer.randomchat.onestore",
    "promotion": "https://lh3.googleusercontent.com/IdjLnWeSWdIbW14VvAEuBuM0Pwg17ZA4p_1IvhVBpg5aHWxTafjenokd667SJ9F2Fg",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
  {
    "code": "superranchat_onestore",
    "icon": "https://play-lh.googleusercontent.com/3eutUxkJdFHX5gnu1oZUnO3Ul2B53dSgV1B9MbQP8uEunLzUGCf1nndp3NCUHau4rRo=w240-h480-rw",
    "name": "랜챗 (원스토어)",
    "package": "kr.jungrammer.superranchat.onestore",
    "promotion": "https://lh3.googleusercontent.com/qzI9Sv_f-qvcABPBowbCvT7lXaDfBbUz6YHfaQigveHFx2C-knrrtnAHLWnatf4KHQ",
    "subscribe": getSubscribeApiCall,
    "unsubscribe": unsubscribeApiCall,
  },
]