import BillingKey from './views/billingkeys';
import {Route, Routes} from "react-router-dom";
import Subscribe from "./views/subscribe";
import Withdraw from "./views/withdraw";

const App = () => {

  return (
    <Routes>
      <Route path="/" element={<Withdraw />}/>
      <Route path="/billing" element={<BillingKey />}/>
      <Route path="/subscribe" element={<Subscribe />}/>
      <Route path="/withdraw" element={<Withdraw />}/>
    </Routes>
  );
}

export default App;
