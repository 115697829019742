import Card from '@mui/material/Card';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Avatar,
  Backdrop,
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import LinkIcon from "@mui/icons-material/Link";
import {applist} from "../const/applist";
import {useEffect, useState} from "react";

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'min-height': '100vh'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const stateMapping = {
  ACTIVE: '활성',
  CANCEL: '취소',
  SUSPENSION: '정지',
  EXPIRED: '만료'
};

function Subscribe() {

  const queryParams = new URLSearchParams(window.location.search);
  const packageParam = queryParams.get('package')
  const userCodeParam = queryParams.get('usercode')

  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [app, setApp] = useState(null)
  const [subscribe, setSubscribe] = useState(null)
  const isValid = (code) => {
    return code.length === 10
  }
  const fetchSubscribe = (app, code) => {
    setLoading(true)
    setError(null)
    app.subscribe(code, ({data}) => {
      setSubscribe(data)
      setLoading(false)
    }, ({response}) => {
      setError(response.data.message)
      setSuccess(null)
      setLoading(false)
    })
  }

  useEffect(() => {
    let app = applist.find((app) => app.package === packageParam)
    let code = userCodeParam
    if (packageParam) {
      setApp(app)
    }
    if (userCodeParam) {
      setCode(code)
    }

    if (app && code) {
      fetchSubscribe(app, code)
    }
  }, []);

  return (
    <Container sx={styles.container}>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              variant="rounded"
              src="https://play-lh.googleusercontent.com/y20GPMNP5WT1eDNADChv3wcRRVj8_PFZVhf3ORQczFVvl604BeUDkFyKaN9ziFZF9APE=s94-rw">
            </Avatar>
          }
          action={
            <a href={"https://play.google.com/store/apps/dev?id=9161681844291235879"} target="_blank">
              <IconButton>
                <LinkIcon/>
              </IconButton>
            </a>
          }
          title="포레스트소프트 주식회사"
          subheader="정기 결제 관리"
        />
        <CardContent>
          <Autocomplete
            id="country-select-demo"
            sx={{ width: 500 }}
            options={applist}
            autoHighlight
            onChange={(event, newValue) => {
              setApp(newValue)
            }}
            value={app}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <Avatar
                  sx={{ width: 36, height: 36 }}
                  src={option.icon}>
                </Avatar>
                &nbsp;&nbsp;{option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="앱을 선택해 주세요"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <br />
          <Box display="flex" component="form">
            <TextField
              required
              fullWidth
              id="outlined-required"
              label="유저코드"
              value={code}
              onChange={(event) => {
                setCode(event.target.value)
              }}
            />
            <Button
              size="large"
              variant="contained"
              disabled={!isValid(code) || !app}
              sx={{ minWidth: 100 }}
              onClick={() => {
                fetchSubscribe(app, code)
              }}
            >
              확인하기
            </Button>
          </Box>

          {error &&
            <Alert severity="error">
              <AlertTitle>Failed</AlertTitle>
              {error}
            </Alert>
          }
          {success &&
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {success}
            </Alert>
          }
          <br />

          {
            subscribe &&
            <Box display="flex">
              <Stack>
                <Typography variant="body1" gutterBottom sx={{ color: '#696969' }}>
                  &nbsp;구독 상태 : {stateMapping[subscribe.state] || '알 수 없음'}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ color: '#696969' }}>
                  &nbsp;시작 시각 : {subscribe.startAt}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ color: '#696969' }}>
                  &nbsp;종료 시각 : {subscribe.expireAt}
                </Typography>
              </Stack>
            </Box>
          }

          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                required
                size={"small"}
                onChange={(event) => {
                  setChecked(event.target.checked)
                }}
              />
            }
            label="정말로 구독을 취소하시겠습니까?"
          />
          <Button
            color="secondary"
            fullWidth
            disabled={!subscribe || !checked || subscribe.state !== 'ACTIVE' || !code}
            size="large"
            style={{
              height: '70px'
            }}
            onClick={() => {
              setLoading(true)
              app.unsubscribe(code, () => {
                fetchSubscribe(app, code)
                setSuccess("구독 취소에 성공했습니다.")
              }, () => {
                setLoading(false)
              })
            }}
          >
            구독 취소하기
          </Button>
          <Typography variant="caption" gutterBottom>
            구글정기결제는&nbsp;
            <a href="https://play.google.com/store/account/subscriptions" target="_blank">
              여기
            </a>
            에서 취소 가능합니다.
          </Typography>
        </CardContent>
      </Card>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
    </Container>
  );
}

export default Subscribe;